import type { Provider } from 'tela-api/src/database/schemas/provider'

export function useProviders() {
    const { api } = useAPI()
    const notification = useToast()
    const providers = useState<Provider[]>('providers', () => [])
    const isFetchingProviders = useState<boolean>('is-fetching-providers', () => false)

    async function fetchProviders() {
        isFetchingProviders.value = true

        const providerList = await api().provider.get()

        if (providerList.error) {
            notification.add({
                title: 'Failed to fetch providers',
                description: providerList.error.message,
                color: 'red',
            })
            return
        }

        isFetchingProviders.value = false
        providers.value = providerList.data

        return providers.value
    }

    return {
        providers,
        fetchProviders,
        isFetchingProviders,
    }
}
